<template>
    <div class="tutorial">
        <img src="@/assets/img/icon/cancel.svg" @click="closeSlide" class="cross btn" alt="">
        <div class="main">
            <div class="card_container" v-if="displayType==='seller'">
                <swiper ref="mySwiper" :options="swiperOptions" class="" @slideChangeTransitionEnd="slideChangeTransitionEnd">
                    <swiper-slide>
                        <div class="slide">
                            <img src="@/assets/img/tutorial-1.gif" alt="">
                            <h3>How to build my block ?</h3>
                            <p>Choose the component you like from the library</p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide">
                            <img src="@/assets/img/tutorial-2.gif" alt="">
                            <h3>How to edit the component ?</h3>
                            <p>Click on the decoration, then use the toolbar on the ride side to edit</p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide">
                            <img src="@/assets/img/tutorial-3.gif" alt="">
                            <h3>How to upload the media ?</h3>
                            <p>Choose the monitor you want to show from media,and upload your picture or video’s link.</p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide">
                            <img src="@/assets/img/tutorial-4.gif" alt="">
                            <h3>Can I use my own 3D models ?</h3>
                            <p>You can upload your 3D model in the customized.</p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide">
                            <img src="@/assets/img/tutorial-5.gif" alt="">
                            <h3>How to introduce my block ?</h3>
                            <p>You should write some introduction and set tags to make your block more attractive.</p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide">
                            <img src="@/assets/img/tutorial-6.gif" alt="">
                            <h3>How to save and publish my block ?</h3>
                            <p>You should save and publish your block so that visitors can visit your block.</p>
                        </div>
                    </swiper-slide>
                </swiper>    
            </div>
            <div class="card_container" v-if="displayType==='user'">
                <swiper ref="mySwiper" :options="swiperOptions" class="" @slideChangeTransitionEnd="slideChangeTransitionEnd">
                    <swiper-slide>
                        <div class="slide">
                            <img src="@/assets/img/tutorial-1-user.gif" alt="">
                            <h3>How to watch the exhibition ?</h3>
                            <p>Use your finger or mouse to click to rotate the angle of view and use the scroll wheel to zoom and right-click to pan</p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide">
                            <img src="@/assets/img/tutorial-2-user.gif" alt="">
                            <h3>Click on the counter</h3>
                            <p>If you want to leave a message to the exhibition, you can click on the counter or click the contact button to fill in the form</p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide">
                            <img src="@/assets/img/tutorial-3-user.gif" alt="">
                            <h3>Click on the exhibition</h3>
                            <p>You can watch the detailed introduction after click on some exhibits</p>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="slide">
                            <img src="@/assets/img/tutorial-4-user.gif" alt="">
                            <h3>Click on the poster or TV</h3>
                            <p>Click on the poster to zoom in to watch and go to the website, click on the TV to watch the video</p>
                        </div>
                    </swiper-slide>
                </swiper>    
            </div>
            <div class="btnWrap">
                <button class="cancel" @click="preSlide" v-visible="paginationIndex>0">Previous</button>
                <button class="submit" @click="nextSlide" v-if="(paginationIndex<5&&displayType==='seller')||(paginationIndex<3&&displayType==='user')">Next</button>
                <button class="submit" @click="closeSlide" v-if="(paginationIndex===5&&displayType==='seller')||(paginationIndex===3&&displayType==='user')">Close</button>
            </div>
            <div class="pagination">
                <ul v-if='displayType===`seller`'>
                    <li v-for="(item,index) in 6" :key="item.pkey" :class="{active:index==paginationIndex}" @click="slideTo(index)"></li>
                </ul>
                <ul v-else>
                    <li v-for="(item,index) in 4" :key="item.pkey" :class="{active:index==paginationIndex}" @click="slideTo(index)"></li>
                </ul>
            </div>  
        </div>  
    </div>
</template>
<script>
import { Swiper, SwiperSlide, directive,Pagination } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

export default {
    name: 'Tutorial',
    components: {
        Swiper,
        SwiperSlide,
    },
  directives: {
    swiper: directive,
  },
    data(){
        return{
            swiperOptions: {
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                    bulletElement : 'li',
                    dynamicBullets: true,
                },
                //pagination: '.swiper-pagination',
                paginationClickable: true,
                slidesPerView:1,
                centeredSlides: true,
                spaceBetween: 40,
                centeredSlidesBounds:true,
                paginationClickable: true,
                //slidesPerGroup:3,
            },
            paginationIndex:0
        }
    },
    mounted() {
      console.log('Current Swiper instance object', this.mySwiper)
      this.swiper.slideTo(0, 1000, false)
    },
    computed:{
        swiper(){
            return this.$refs.mySwiper.$swiper
        },
    },
    props:{
        displayType:{
            default: 'seller',
        }
    },
    methods: {
        nextSlide(){
            this.$refs.mySwiper.$swiper.slideNext()
            this.paginationIndex = this.swiper.activeIndex
        },
        preSlide(){
            this.$refs.mySwiper.$swiper.slidePrev()
            this.paginationIndex = this.swiper.activeIndex
        },
        slideChangeTransitionEnd(){
            this.paginationIndex = this.swiper.activeIndex;
        },
        slideTo(idx){
            this.swiper.slideTo(idx, 1000, false)
            this.paginationIndex = this.swiper.activeIndex
        },
        closeSlide(){
            this.$emit('closeSlide');
        }
    },
    
}
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    .swiper-slide{
        @media (max-width:900px) {
            // width: 100% !important;
            // margin: 0 !important;
        }
    }
    .tutorial{
        background-color: #fff;
        position: fixed;
        top:0;
        left:0;
        bottom:0;
        right:0;
        z-index: 9999;
        .cross{
            position: absolute;
            right:0;
            top:0;
            width: 28px;
            z-index: 999;
        }
        .main{
            position: relative;
            top:50%;
            transform:translateY(-50%);
        }
        .card_container{
            margin-bottom: 2%;
        }
        h3{
            font-size: 20*$font;
            @include for-size(tablet-landscape-only) {
                font-size: 14px;
            }
        }
        p{
            font-size: 17*$font;;
            line-height: 1.5;
            @include for-size(tablet-landscape-only) {
                font-size: 12px;
            }
            margin: 0 30px;
        }
        .slide{
            margin: 0 auto;
            width: 60%;
            @media (max-width:900px) {
                width: 100%;
            }
            img{
                width:100%;
            }
        }
        .btnWrap{
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
            min-width: 300px;
        }
        button{
            font-size: 17*$font;
            line-height: 2.5;
            width: 100px;
            height: 40px;
            border-radius: 7px;
            margin: 0 6%;
            @include for-size(tablet-landscape-only) {
                font-size: 12px;
            }
        }
        .pagination{
            // min-width: 900px;
            @media (max-width:600px) {
                min-width: 0;
            }
            ul{
                display: flex;
                width: 70%;
                margin: 0 auto;
                justify-content: center;
                li{
                    width: 10px;
                    height: 10px;
                    background-color: #B2B1B1;
                    border-radius: 100%;
                    margin: 0 10px;
                    @include for-size(tablet-landscape-only) {
                        width:8px;
                        height:8px;
                    }
                    &.active{
                        background-color: $purple; 
                    }
                }
            }
        }
    }
</style>